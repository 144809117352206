import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { MdDone } from "react-icons/md";
import { useNavigate } from "react-router";
import RegisterNow from '../../../assets/headerImgs/RegisterNow.png';
import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { industries } from "../../../store/AllListData";
import { getURLbyEndPointV2 } from "../../../store/api";
import { country_list } from "../../auth/Register/AllRegiPageLists";
import "../../auth/Register/Register.css";
import { Link } from "react-router-dom";

export const Profession = [
    "Mentor",
    "Lawyer",
    "Accountant",
    "Arbitrator Center",
    "New Market consultant",
    "Financial Adviser",
    'ADR PROFESSIONAL',
    "Chartered Accountant",
    "Company Secretary",
    "Export Adviser",
    "Commercial Adviser",
    "Innovation Adviser",
    "Digital Service Specialist",
    "Marketing Specialist",
    "Other",
];


const B2BEventBookings = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);

    const navigate = useNavigate()


    const countryDates = {
        Nigeria: "2024-12-23",
        Germany: "2024-12-23"
    };

    const countries = ["Nigeria", "Germany"];
    const slotTimes = {
        Nigeria: [
            { time: "02:00 PM - 02:30 PM", price: 4000 },
            { time: "02:45 PM - 03:15 PM", price: 4000 },
            { time: "03:30 PM - 04:00 PM", price: 4000 },
            { time: "04:15 PM - 04:45 PM", price: 4000 },
            { time: "05:00 PM - 05:30 PM", price: 4000 },
            { time: "05:45 PM - 06:15 PM", price: 4000 },
        ],
        Germany: [
            { time: "02:00 PM - 02:30 PM", price: 4000 },
            { time: "02:45 PM - 03:15 PM", price: 4000 },
            { time: "03:30 PM - 04:00 PM", price: 4000 },
            { time: "04:15 PM - 04:45 PM", price: 4000 },
            { time: "05:00 PM - 05:30 PM", price: 4000 },
            { time: "05:45 PM - 06:15 PM", price: 4000 },
        ],
    };

    const initialFormData = {
        title: '',
        firstName: '',
        surName: '',
        companyName: '',
        email: '',
        businessCategory: '',
        phoneNo: '',
        inputNumber: '',
        gstNo: '',
        address1: '',
        address2: '',
        country: '',
        city: '',
        state: '',
        pinCode: '',
        password: "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleConfirmChange = (e) => {
        const { value } = e.target;
        setConfirmPassword(value);
    };


    const handleSlotSelection = (country, time, price) => {
        // Check if the selected time slot is already selected for a different country on the same date
        const isSlotAlreadySelectedForOtherCountry = selectedSlots.some(
            slot => slot.time === time && slot.date === countryDates[country] && slot.country !== country
        );

        if (isSlotAlreadySelectedForOtherCountry) {
            alert(`You cannot select the same time slot (${time}) for a different country on the same date.`);
            return;
        }

        // Remove any conflicting slots (same date and time or same country)
        const newSelectedSlots = selectedSlots.filter(
            slot =>
                !(slot.time === time && slot.date === countryDates[country]) && // Same time & date
                !(slot.country === country) // Same country
        );

        // Add the new slot
        newSelectedSlots.push({
            country,
            time,
            price,
            date: countryDates[country],
        });

        // Update the state with the new selection
        setSelectedSlots(newSelectedSlots);
    };


    useEffect(() => {
        const newTotalPrice = selectedSlots.reduce((total, slot) => total + slot.price, 0);
        setTotalPrice(newTotalPrice);
    }, [selectedSlots]);


    const validateCurrentStep = () => {
        switch (currentStep) {
            case 0:
                return (
                    formData.title &&
                    formData.firstName &&
                    formData.surName &&
                    formData.companyName &&
                    formData.email &&
                    formData.businessCategory &&
                    formData.phoneNo &&
                    formData.inputNumber &&
                    formData.gstNo &&
                    formData.address1 &&
                    formData.country &&
                    formData.city &&
                    formData.state &&
                    formData.pinCode &&
                    formData.password &&
                    confirmPassword
                );
            case 1:
                return selectedSlots.length > 0;
            default:
                return true;
        }
    };


    const handleRegiFormSubmit = async (e) => {
        e.preventDefault();

        // Validate password match
        if (formData.password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        // Combine all form data
        const submissionData = {
            personalDetails: formData,
            slotBookings: selectedSlots,
            totalPrice: totalPrice
        };

        try {
            const res = await axios.post(
                getURLbyEndPointV2("createB2bRegistration"),
                { submissionData }
            );

            if (res && res.data && res.data.status) {
                toast.success(res.data.message);
                setTimeout(() => {
                    navigate('/login');
                }, 4000);
            } else {
                console.log("Response data:", res);
                toast.error(res?.data?.message || "An error occurred");
            }
        } catch (e) {
            console.log("Error caught:", e);
            toast.error(e?.response?.data?.message || "An error occurred");
        }
    };

    const handleNext = () => {
        if (validateCurrentStep()) {
            if (currentStep < steps.length - 1) {
                setCurrentStep(currentStep + 1);
            }
        } else {
            alert('Please fill in all required fields before proceeding.');
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };


    const steps = [
        {
            name: "Registration Form",
            content: (
                <>
                    <div
                        className="text-white montserratFont"
                    >
                        <p className="mt-4" style={{ fontSize: "0.9em" }}>
                            * indicates required fields
                        </p>
                        <Row className='my-4'>
                            <Col xs={12} sm={12} lg={2}>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    Title *
                                </label>
                                <div className="d-flex flex-column">
                                    <select
                                        className="form-control rounded-0"
                                        name="title"
                                        onChange={handleInputChange}
                                        value={formData.title}
                                        required
                                    >
                                        <option value="">Select..</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                        <option value="Dr">Dr.</option>
                                        <option value="Prof">Prof.</option>
                                        <option value="Shri">Shri.</option>
                                        <option value="Hon">Hon.</option>
                                        <option value="Gov">Gov.</option>
                                    </select>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={5}>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    First Name *
                                </label>
                                <div className="d-flex flex-column">
                                    <input
                                        style={{ width: "auto", height: "auto" }}
                                        className="form-control rounded-0"
                                        onChange={handleInputChange}
                                        value={formData.firstName}
                                        name="firstName"
                                        type="text"
                                        maxLength={50}
                                        required
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={5}>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    Last Name *
                                </label>
                                <div className="d-flex flex-column">
                                    <input
                                        style={{ width: "auto", height: "auto" }}
                                        className="form-control rounded-0"
                                        name="surName"
                                        type="text"
                                        onChange={handleInputChange}
                                        value={formData.surName}
                                        maxLength={50}
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col xs={12} sm={12} lg={6}>
                                <div>
                                    <label
                                        className="fw-bold"
                                        style={{ fontSize: "0.9rem" }}
                                    >
                                        Company Name *
                                    </label>
                                    <div className="d-flex flex-column">
                                        <input
                                            style={{ width: "auto", height: "auto" }}
                                            className="form-control rounded-0"
                                            name="companyName"
                                            onChange={handleInputChange}
                                            value={formData.companyName}
                                            type="text"
                                            maxLength={50}
                                            required
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={6}>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    Email Address *
                                </label>
                                <div className="d-flex flex-column">
                                    <input
                                        style={{ width: "auto", height: "auto" }}
                                        className="form-control rounded-0"
                                        name="email"
                                        type="email"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        maxLength={30}
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "1rem" }}
                                >
                                    Password *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onChange={handleInputChange}
                                    value={formData.password}
                                    name="password"
                                    type="password"
                                    maxLength={15}
                                    required
                                />
                            </Col>
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "1rem" }}
                                >
                                    Confirm Password *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onChange={handleConfirmChange}
                                    value={confirmPassword}
                                    name="confirmPassword"
                                    type="text"
                                    maxLength={15}
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="my-4">
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label
                                            className="fw-bold"
                                            style={{ fontSize: "0.9rem" }}
                                        >
                                            Select Your Industry *
                                        </label>
                                        <div className="d-flex flex-column">
                                            <select
                                                className="form-control rounded-0"
                                                required
                                                name="businessCategory"
                                                value={formData.businessCategory}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select Industry...</option>
                                                {industries.map((data, index) => (
                                                    <option key={index} value={data}>
                                                        {data}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <label
                                        className="fw-bold"
                                        style={{ fontSize: "0.9rem" }}
                                    >
                                        Phone Number *
                                    </label>
                                    <input
                                        className="form-control rounded-0"
                                        name="phoneNo"
                                        value={formData.phoneNo}
                                        onChange={handleInputChange}
                                        type="number"
                                        maxLength={12}
                                        required
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Row className='my-4'>
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    IEC CODE / CIN NO *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    name="inputNumber"
                                    onChange={handleInputChange}
                                    value={formData.inputNumber}
                                    type="text"
                                    maxLength={15}
                                    required
                                />
                            </Col>
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    GST NO. *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    name="gstNo"
                                    onChange={handleInputChange}
                                    value={formData.gstNo}
                                    type="text"
                                    maxLength={20}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    Address 1 *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onChange={handleInputChange}
                                    value={formData.address1}
                                    name="address1"
                                    pattern="[a-zA-Z0-9,. ]*"
                                    type="text"
                                    maxLength={50}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    Address 2
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onChange={handleInputChange}
                                    value={formData.address2}
                                    name="address2"
                                    type="text"
                                    pattern="[a-zA-Z0-9,. ]*"
                                    maxLength={50}
                                />
                            </Col>
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    Country *
                                </label>
                                <select
                                    className="form-control rounded-0"
                                    required
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    name="country"
                                >
                                    <option value="">Select a country...</option>
                                    {country_list.map((country, index) => (
                                        <option key={index} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    City *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onChange={handleInputChange}
                                    value={formData.city}
                                    name="city"
                                    required
                                    maxLength={30}
                                />
                            </Col>
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    State *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onChange={handleInputChange}
                                    value={formData.state}
                                    name="state"
                                    type="text"
                                    maxLength={30}
                                    required
                                />
                            </Col>
                            <Col>
                                <label
                                    className="fw-bold"
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    pinCode *
                                </label>
                                <input
                                    style={{ height: "auto" }}
                                    className="form-control rounded-0"
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value) || 0
                                        )
                                            .toString()
                                            .slice(0, 6);
                                    }}
                                    onChange={handleInputChange}
                                    value={formData.pinCode}
                                    name="pinCode"
                                    type="text"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    minLength={6}
                                    maxLength={6}
                                    required
                                />
                            </Col>
                        </Row>
                    </div>
                </>
            ),
        },
        {
            name: "Slot booking",
            content: (
                <div className="text-white montserratFont">
                    <h5 className="text-white fs-2">Slot Booking</h5>
                    <hr />
                    {countries.map((country) => (
                        <div key={country} className="mb-4">
                            <h5 className="text-white">
                                {country} Slots - Date: {countryDates[country]}
                            </h5>
                            <Row>
                                {slotTimes[country].map((slot, index) => (
                                    <Col key={index} xs={12} sm={4} className="mb-2">
                                        <Button
                                            variant={
                                                selectedSlots.some(s => s.time === slot.time && s.country === country)
                                                    ? "success"
                                                    : "outline-light"
                                            }
                                            onClick={() => handleSlotSelection(country, slot.time, slot.price)}
                                            disabled={
                                                selectedSlots.some(s => s.date === countryDates[country] && s.time === slot.time && s.country !== country)
                                            }
                                            className="w-100"
                                        >
                                            {slot.time} - ₹{slot.price}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                    <div className="mt-4">
                        <h5 className="text-white">Selected Slots:</h5>
                        {selectedSlots.length === 0 ? (
                            <p>No slots selected</p>
                        ) : (
                            selectedSlots.map((slot, index) => (
                                <div key={index} className="text-white">
                                    {slot.country} - Date: {slot.date}: {slot.time} - ₹{slot.price}
                                </div>
                            ))
                        )}
                    </div>

                    <div className="mt-4">
                        <h4 className="text-white">Total Price: ₹{totalPrice}</h4>
                    </div>
                </div>
            ),
        },
        {
            name: "Terms & Conditions",
            content: (
                <div className="mt-4 text-white">
                    <h5 className="text-white fs-2">Terms & Conditions</h5>
                    <hr />
                    <p>
                        <strong>Virtual B2B Forum:</strong> format will be ‘One-To-One’ trade
                        discussion for 30 minutes each slot. Maximum of 6 sessions per country
                        will be scheduled in a day.
                    </p>
                    <p>
                        <strong>Terms and Conditions of Participation:</strong>
                    </p>
                    <ul>
                        <li>
                            <strong>Fees chargeable for VTF:</strong>
                            <ul>
                                <li>For Non-Members standard fees - INR 4000 per Country.</li>
                                <li>
                                    For Members, a discount of 20% is applicable on standard
                                    fees.
                                </li>
                            </ul>
                        </li>
                        <li>Maximum one representative per session will be allowed.</li>
                        <li>
                            All bookings are non-transferable. The registration is valid only
                            for the individual participant or entity that made the booking.
                        </li>
                        <li>
                            Fees for the booking confirmation will be paid through AECCI
                            e-Platform and will be deducted from your wallet directly. Please
                            ensure to maintain the minimum amount according to the number of
                            chosen countries.
                        </li>
                        <li>
                            Bookings will be on a first-come, first-served basis.
                        </li>
                        <li>
                            Once your booking is confirmed, you will receive a confirmation
                            email with your slot timing details. Please review this
                            information carefully.
                        </li>
                        <li>
                            Each business is suggested to adhere to the slot’s timings.
                            Delayed entries will be treated as cancelled.
                        </li>
                        <li>
                            By proceeding with your booking, you acknowledge and agree to the
                            above-given terms and conditions.
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            name: "Review",
            content: (
                <div className="text-white montserratFont">
                    <h5 className="text-white fs-2">Slot Details</h5>
                    <hr />
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Name:</strong> {formData.title} {formData.firstName} {formData.surName}
                        </Col>
                        <Col md={6}>
                            <strong>Company:</strong> {formData.companyName}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Email:</strong> {formData.email}
                        </Col>
                        <Col md={6}>
                            <strong>Phone:</strong> {formData.phoneNo}
                        </Col>
                    </Row>

                    <h4 className="mt-4 mb-3">Slot Booking Details</h4>
                    {selectedSlots.length === 0 ? (
                        <p className="text-warning">No slots have been booked</p>
                    ) : (
                        selectedSlots.map((slot, index) => (
                            <Row key={index} className="mb-3 bg-dark p-3 rounded">
                                <Col md={4}>
                                    <strong>Country:</strong> {slot.country}
                                </Col>
                                <Col md={4}>
                                    <strong>Date:</strong> {slot.date}
                                </Col>
                                <Col md={4}>
                                    <strong>Time Slot:</strong> {slot.time}
                                </Col>
                                <Col md={12} className="mt-2">
                                    <strong>Price:</strong> ₹{slot.price}
                                </Col>
                            </Row>
                        ))
                    )}
                    <div className="mt-4">
                        <h4>Total Booking Price: ₹{totalPrice}</h4>
                    </div>
                </div>
            ),
        },
    ];


    return (
        <>
            <Layout>
                <EPlatformHeader
                    heading="Book Your Slot - AECCI Virtual B2B Forum"
                    text2="Nigeria 23th Dec 2024 - Germany  23rd Dec 2024"
                    sideImg={RegisterNow}
                />
                <div className="custom-width mx-auto my-4">
                    <Row className="mt-2">
                        <Col sm={12} lg={5}>
                            <div className="content-section">
                                <h4 className="montserratAlternatesFont fw-bold">
                                    Do you find this important but ‘too-costly’ to fly abroad for exploring business opportunities?
                                </h4>
                                <h5 className="my-3 fw-bold">
                                    AECCI ‘Virtual B2B Forum’ is back with an opportunity to meet global experts at the comfort of your business.
                                </h5>
                                <h6 className="my-4 text-justify fw-semibold">
                                    Being your trade association, we understand the complexities of business, necessities to expand, and limitations to spend.
                                    To help you overcome such challenges, we have collaborated with 30+ expert partners within the Asia and Africa region from the
                                    prominent trade partner countries: Germany, Nigeria, Vietnam, Cambodia, Oman, Brazil, Egypt, and many more.
                                </h6>
                                <h6 className="my-4 text-justify fw-semibold">
                                    With this, AECCI marks its presence in 30+ countries. To make your global business journey an enriching experience,
                                    we are bringing back the “Virtual Business Forum” with opportunities to have “one-to-one 30-minute interaction sessions”
                                    with our collaborative expert partners.
                                </h6>
                                <h5 className="my-4 text-justify fw-bold">In this round, you can meet the experts from:</h5>
                                <ul className="my-2 text-justify fw-semibold">
                                    <li className='fw-bold fs-5'>Nigeria on 23rd December, 2024</li>
                                    <li className='fw-bold fs-5'>Germany on 23rd December, 2024</li>
                                </ul>
                                <h6 className="my-4 text-justify fw-semibold">
                                    Likewise, we will keep scheduling sessions with various foreign experts and insist you explore the opportunities
                                    to seek advice on all unanswered queries, such as:
                                </h6>
                                <ul className="my-4 text-justify fw-semibold">
                                    <li>Reaching new customers</li>
                                    <li>Business expansion</li>
                                    <li>Credibility checks of trade partners</li>
                                    <li>Compliances and regulations in foreign lands</li>
                                    <li>Product or brand registrations in the chosen country</li>
                                    <li>Resolving commercial disputes</li>
                                    <li>Seeking investment partners</li>
                                    <li>Fulfilling IPR needs</li>
                                    <li>And other such issues</li>
                                </ul>
                                <h6 className="my-4 text-justify fw-semibold">
                                    Simply  <Link to="/b2b-event-booking" className="">click</Link> to join us for the big event, where you can find every possible solution.
                                </h6>
                                <h6 className="my-4 text-justify fw-semibold">
                                    <Link to="/aecci-virtual-b2b-forum" className=""> Click to explore </Link> our AECCI Virtual B2B Forum at the comfort of your workplace.
                                </h6>
                            </div>

                        </Col>
                        <Col sm={12} lg={7} >
                            <div style={{
                                background: "#021C43",
                                padding: "40px",
                            }}>
                                <Form>
                                    <div className="mx-auto mt-5">
                                        <div className="progress px-1" style={{ height: "3px" }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: `${((currentStep) / steps.length) * 100}%` }}
                                                aria-valuenow={(currentStep + 1) * 2}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                        <div className="step-container d-flex justify-content-between">
                                            {steps.map((step, index) => (
                                                <div key={index} className="step-item text-center">
                                                    <div className={`step-circle ${currentStep >= index ? "active" : ""}`}>
                                                        {currentStep > index ? <MdDone /> : index + 1}
                                                    </div>
                                                    <div className="step-label text-white">{step.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-2">
                                        {steps[currentStep].content}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        {currentStep > 0 && (
                                            <Button variant="secondary" onClick={handlePrevious}>
                                                Previous
                                            </Button>
                                        )}
                                        {currentStep < steps.length - 1 ? (
                                            <Button variant="primary" onClick={handleNext}>
                                                Next
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="success"
                                                onClick={handleRegiFormSubmit}
                                            >
                                                Register
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default B2BEventBookings;